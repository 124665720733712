<template>
  <div>
    <aside class="aside-translate">
      <button id="switch-country" class="btn-country" @click="showCountry()">
        <div id="flag-btn" :class="btn_flag"></div>

        <ul id="c-list" class="btn-country-list btn-show" role="menubar">
          <!-- -->
          <li
            role="menuitem"
            tabindex="0"
            @keypress.13="switchCountry('flag1')"
          >
            <div
              @click="switchCountry('flag1')"
              id="flag1"
              :class="flags[0].flag"
            ></div>
          </li>
          <li
            role="menuitem"
            tabindex="0"
            @keypress.13="switchCountry('flag2')"
          >
            <div
              @click="switchCountry('flag2')"
              id="flag2"
              :class="flags[1].flag"
            ></div>
          </li>
          <li
            role="menuitem"
            tabindex="0"
            @keypress.13="switchCountry('flag3')"
          >
            <div
              @click="switchCountry('flag3')"
              id="flag3"
              :class="flags[2].flag"
            ></div>
          </li>
          <li role="menuitem" tabindex="0">
            <div
              @click="switchCountry('flag4')"
              id="flag4"
              :class="flags[3].flag"
            ></div>
          </li>
        </ul>
      </button>
    </aside>
  </div>
</template>
<script>
import "vue-google-translate";
import flags from "../../store/modules/flags";
export default {
  data() {
    return {
      btn_flag: flags.state.flags[0].flag,
      flags: flags.state.flags
    };
  },
  created() {},
  computed: {
    arrayOfCountries() {
      return [
        {
          code: "en|pt",
          title: "Portuguese"
        },
        {
          code: "en|en",
          title: "English"
        },
        {
          code: "en|de",
          title: "German"
        },
        {
          code: "en|es",
          title: "Spanish"
        }
      ];
    }
  },
  methods: {
    doTranslation(code) {
      window.doGTranslate(code);
    },
    switchCountry(id, code) {
      const br = "flag-br";
      const uk = "flag-uk";
      const ger = "flag-ger";
      const es = "flag-es";
      var id_flag = document.getElementById(id);

      if (id_flag.className === br) {
        this.$store.dispatch("switch_country", br);
        code = this.arrayOfCountries[0].code;
        this.doTranslation(code);
        this.btn_flag = flags.state.flags[0].flag;
      }
      if (id_flag.className === uk) {
        this.$store.dispatch("switch_country", uk);
        code = this.arrayOfCountries[1].code;
        this.doTranslation(code);
        this.btn_flag = flags.state.flags[1].flag;
      }
      if (id_flag.className === ger) {
        this.$store.dispatch("switch_country", ger);
        code = this.arrayOfCountries[2].code;
        this.doTranslation(code);
        this.btn_flag = flags.state.flags[2].flag;
      }
      if (id_flag.className === es) {
        this.$store.dispatch("switch_country", es);
        code = this.arrayOfCountries[3].code;
        this.doTranslation(code);
        this.btn_flag = flags.state.flags[3].flag;
      }
    },

    showCountry() {
      document.getElementById("c-list").classList.toggle("btn-show");
    }
  }
};
</script>
<style scoped>
@import url("./index.css");
</style>
