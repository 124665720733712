<template>
  <div
    class="header-container"
    @keypress.13="openSubMenu('main-nav')"
    @mouseover="hover = true"
    @mouseleave="openSubMenu('main-nav')"
    tabindex="0"
  >
    <header class="header" role="banner">
      <button
        id="btnlogo"
        @touchstart="openSubMenu('main-nav')"
        class="btn-nav hidden-lmg"
      >
        <span class="menu-nav" v-if="!hover">
          <span></span>
        </span>
        <span class="icon-close" v-else>
          <span></span>
        </span>
      </button>
      <div class="logo" id="logo">
        <a
          href="javascript:void(0);"
          @click="goToMainOrOpenMainNav()"
          title="Shinier"
        >
          <img
            src="../../assets/icons/shinier-icon.png"
            style="margin: auto"
            alt="Shinier"
            v-if="!hover"
            height="70"
          />
          <img
            id="logo-open"
            style="margin: auto"
            src="../../assets/icons/Logo (1).png"
            v-else
            alt="Shinier"
            height="70"
          />
        </a>

        <Translator />
       <!--  <div class="aside-top">
          <a class="btn btn-orange-solid" id="backToTop" @click="scroll()">
            <font-awesome-icon
              id="arrow-top-btn"
              :icon="['fa', 'arrow-up']"
            ></font-awesome-icon>
          </a>
        </div> -->
      </div>

      <div id="main-nav" class="nav-group">
        <nav class="nav nav-main" role="navigation" aria-label="Menu principal">
          <div class="menu-menu-principal-container">
            <ul role="menubar" id="menu-menu-principal" class="menu">
              <!-- Abre menu soluções -->
              <li
                aria-haspopup="true"
                role="menuitem"
                tabindex="0"
                id="li-menu-item-solucoes"
                @keypress.13="getSubmenu('subm1')"
                @click="getSubmenu('subm1')"
                class="li li-master icon-solucoes menu-item menu-item-has-children"
              >
                <a
                  href="javascript:void(0);"
                  @click="
                    'max-width: 1024px' ? null : pushRoute('/solucoes/todas/')
                  "
                >
                  <span>Módulos</span>
                  <i class="icon-arrow-right"></i>
                  <i class="icon-arrow-right"></i>
                  <i class="icon-arrow-right"></i>
                </a>
                <ul class="sub-menu" id="subm1" v-show="hoverLi == true">
                  <li role="none" id="menu-item-281" class="menu-item">
                    <a
                      href="#"
                      role="menuitem"
                      tabindex="0"
                      @click="pushRoute('/solucoes/todas/')"
                      >Visão geral</a
                    >
                  </li>
                  <li
                    role="none"
                    id="menu-item-282"
                    class="icon-gold menu-item menu-item-object-solucoes"
                  >
                    <a
                      href="javascript:void(0);"
                      role="menuitem"
                      tabindex="0"
                      @click="
                        pushRoute(
                          '/solucoes/gold-construir-loja-online-com-nosso-omnichannel/'
                        )
                      "
                      >Gold - Omnichannel</a
                    >
                  </li>

                  <li
                    role="none"
                    id="menu-item-285"
                    class="icon-diamond menu-item menu-item-object-solucoes"
                  >
                    <a
                      href="javascript:void(0);"
                      role="menuitem"
                      tabindex="0"
                      @click="pushRoute('/solucoes/diamond-por-que-devo-investir-em-um-marketplace/')"
                      >Diamond - marketplaces</a
                    >
                  </li>

                  <li
                    role="none"
                    id="menu-item-285"
                    class="icon-ametista menu-item menu-item-object-solucoes"
                  >
                    <a
                      href="javascript:void(0);"
                      role="menuitem"
                      tabindex="0"
                      @click="
                        pushRoute(
                          '/solucoes/ametista-software-gestao-educacional/'
                        )
                      "
                      >Ametista - LMS</a
                    >
                  </li>

                  <li
                    role="none"
                    id="menu-item-43822"
                    class="icon-opal menu-item menu-item-object-solucoes"
                  >
                    <a
                      href="javascript:void(0);"
                      role="menuitem"
                      tabindex="0"
                      @click="pushRoute('/solucoes/opal-gestao-de-estoques/')"
                      >Opal - WMS</a
                    >
                  </li>
                  <li
                    role="none"
                    id="menu-item-43821"
                    class="icon-jade menu-item menu-item-object-solucoes"
                  >
                    <a
                      href="javascript:void(0);"
                      role="menuitem"
                      tabindex="0"
                      @click="
                        pushRoute('/solucoes/jade-sistema-gestao-ao-vivo/')
                      "
                      >Jade - LiMS</a
                    >
                  </li>
                  <li
                    role="none"
                    id="menu-item-282"
                    class="icon-quartz menu-item menu-item-object-solucoes"
                  >
                    <a
                      href="javascript:void(0);"
                      role="menuitem"
                      tabindex="0"
                      @click="
                        pushRoute(
                          '/solucoes/quartz-servico-de-suporte-a-sistemas/'
                        )
                      "
                      >Quartz - AMS</a
                    >
                  </li>
                  <li
                    id="menu-item-23861"
                    class="fas fa-wifi menu-item menu-item-has-children"
                  >
                    <!-- <a>Módulos IoT</a> -->
                    <ul class="sub-menu">
                      <li
                        role="none"
                        id="menu-item-288"
                        class="icon-garnet menu-item menu-item-object-solucoes"
                      >
                        <a
                          href="javascript:void(0);"
                          role="menuitem"
                          tabindex="0"
                          @click="
                            pushRoute('/solucoes/garnet-como-aplicar-iot/')
                          "
                          >Garnet - Beacons</a
                        >
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <!-- Fecha menu soluções -->

              <!-- Abre menu segmentos -->
              <li
                role="menuitem"
                tabindex="0"
                id="menu-item-300"
                class="li li-master icon-fabrica menu-item"
                @keypress.13="
                  pushRoute('/shinier/principais-duvidas-fabrica-de-software/')
                "
              >
                <a
                  tabindex="0"
                  href="javascript:void(0);"
                  @click="
                    pushRoute(
                      '/shinier/principais-duvidas-fabrica-de-software/'
                    )
                  "
                >
                  <span>Fábrica de Software</span>
                  <i class="icon-arrow-right"></i>
                  <i class="icon-arrow-right"></i>
                  <i class="icon-arrow-right"></i>
                </a>
              </li>
              <!-- fecha menu segmentos -->

              <!-- Abre menu shinier novo-->
              <li
                id="li-menu-item-shinier"
                class="li li-master icon-shinier menu-item menu-item-has-children"
                aria-haspopup="true"
                role="menuitem"
                tabindex="0"
                @keypress.13="getSubmenu('subm2')"
                @click="getSubmenu('subm2')"
              >
                <a
                  href="javascript:void(0);"
                  @click="
                    pushRoute(
                      '/shinier/sobre-a-shinier-nossa-missao-e-historia/'
                    )
                  "
                >
                  <span class="notranslate">Sobre</span>
                  <i class="icon-arrow-right"></i>
                  <i class="icon-arrow-right"></i>
                  <i class="icon-arrow-right"></i>
                </a>
              </li>
              <!-- fecha menu shinier novo-->

              <!-- Abre menu artigos-->
              <li
                role="menuitem"
                tabindex="0"
                id="li-menu-item-artigos"
                class="li-master icon-artigo menu-item"
                @keypress.13="pushRoute('/artigos/')"
              >
                <a href="javascript:void(0);" @click="pushRoute('/artigos/')">
                  <span>Aceleração</span>
                  <i class="icon-arrow-right"></i>
                </a>
              </li>
              <!-- fecha menu artigos-->

              <!-- Abre menu shinier-->
              <li
                v-if="check_key('index.message.api')"
                id="menu-item-23734"
                class="li-master menu-item"
              >
                <a @click="$router.push({ name: 'Messages' })">
                  <span>Mensagens</span>
                  <i class="icon-arrow-right"></i>
                </a>
              </li>
              <!-- fecha menu shinier-->
            </ul>
          </div>
          <!--menu-menu-principal-container -->
        </nav>

        <a
          @click="$router.open({ name: 'Login' })"
          rel="me"
          class="button-login"
          style="display: flex"
        >
          <i class="icon-login"></i>
          <strong style="margin-top: 5px">Faça login</strong>
        </a>
        <span class="tip-login"
          >Faça login rapidamente nas soluções da Shinier através deste
          botão!</span
        >
      </div>

      <div class="search-box">
        <div class="container">
          <form
            class="form-search"
            method="get"
            action="https://www.shinier.com.br"
            novalidate="novalidate"
          >
            <input
              maxlength="100"
              name="s"
              class="search-input required"
              placeholder="Buscar"
              type="search"
            />
            <button type="submit" class="search-button">
              <i class="icon-lupa"></i>
            </button>
          </form>
        </div>
      </div>
    </header>
  </div>
</template>
<script>
import api from "@/services";
import store from "../../store";
import { toast } from "@/utils";
import $ from "jquery";
import Translator from "../Translator";

export default {
  components: {
    Translator,
  },
  data() {
    return {
      rules: {},
      hover: false,
      hoverLi: false,
      route: "",
    };
  },
  methods: {
    pushRoute(path) {
      if (this.$route.fullPath !== path) {
        document.getElementById("main-nav").classList.remove("display-menu");
        this.menuItemIterator();
        this.$router.push(path);
      }
    },
    getRules() {
      api.get("role/index").then(
        (response) => {
          if (response.status === 200) {
            this.rules = response.data.roles;
          }
        },
        (error) => {
          error.response.data.errors.forEach((msg) => {
            toast(this, "is-danger", msg);
          });
          return Promise.reject(error);
        }
      );
    },
    menuItemIterator() {
      var menus = document.querySelectorAll("[id^=subm]");
      for (var i = 0; i < menus.length; i++) {
        menus[i].style.display = "none !important";
        this.hoverLi = false;
        this.hover = false;
      }
    },

    goToMainOrOpenMainNav() {
      !this.route ? this.pushRoute("/") : this.openSubMenu("main-nav");
    },

    getSubmenu(id, display) {
      var id_ = document.getElementById(id);
      display = id_.style.display === "block";
      if (display) {
        id_.style.display = "none";
      } else {
        id_.style.display = "block";
      }
    },

    openSubMenu(sub) {
      sub = document.getElementById("main-nav");
      var windowWidth = window.innerWidth;
      if (this.hover) {
        this.menuItemIterator();
      }

      windowWidth < 1025
        ? document.getElementById("btnlogo").addEventListener(
            "click",
            () => {
              sub.classList.toggle("display-menu");
            },
            { passive: true }
          )
        : null;
    },

   /*  scroll() {
      window.scroll({ top: 0, behavior: "smooth" });
    }, */

    check_key(key) {
      let find = false;
      $.each(this.rules, function (id, rule) {
        if (rule.key === key) {
          find = true;
        }
      });
      return find;
    },
  },
  beforeCreate() {
    this.route = this.$route.name === "Home";
    if (store.getters.isLogged) {
      this.getRules();
    }
  },
};
</script>
<style>
@import url("./index.css");
</style>
